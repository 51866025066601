import React, { useCallback, useEffect, useState } from "react";

import GoogleMapReact from "google-map-react";
import { wktToGeoJSON } from "@terraformer/wkt"
import { GeoJSON } from "geojson";
import { useMapDispatch, useMapState } from "../../../context/mapContext";
import {
  SET_CENTER,
  SET_ZOOM,
  SET_MAP_TYPE_ID,
  SET_BOUNDS,
} from "../../../context/actions/mapActions";
import { useAppState } from "../../../context/appContext";
import {
  useVehicleDispatch,
  useVehicleState,
} from "../../../context/vehicleContext";
import {
  SET_IS_LOADING_VEHICLE_GEOLOCATION,
  SET_SELECTED_VEHICLE_GEOLOCATION,
} from "../../../context/actions/vehicleActions";

const getFormattedStreet = (addressComponents: any[]): string => {
  const roadName = addressComponents[1]?.short_name;
  const number = addressComponents[0]?.long_name;
  if (roadName && number) {
    return `${roadName} ${number}`;
  }
  return roadName ?? number ?? "";
};

const getFormattedArea = (addressComponents: any[]): string => {
  const zipCode = addressComponents[6]?.long_name;
  const city = addressComponents[3]?.long_name;
  const region = addressComponents[5]?.long_name;

  if (zipCode && city && region) {
    return `${zipCode} ${city}, ${region}`;
  }
  if (zipCode && city) {
    return `${zipCode} ${city}`;
  }
  if (city && region) {
    return `${city}, ${region}`;
  }
  if (zipCode && region) {
    return `${zipCode}, ${region}`;
  }
  return zipCode ?? city ?? region ?? "";
};

export const GoogleMapView = ({ children }: { children: React.ReactNode }) => {
  const {
    defaultCenter,
    defaultZoom,
    center,
    zoom,
    bounds,
    mapTypeId,
  } = useMapState();
  const { appConfig } = useAppState();
  const { selectedVehicle, selectedVehicleGeolocation, selectedRoadTypes } = useVehicleState();

  const mapDispatch = useMapDispatch();
  const vehicleDispatch = useVehicleDispatch();

  const [mapsApi, setMapsApi] = useState<any>(null);
  const [mapApi, setMapApi] = useState<any>(null);
  const [roadLayer, setRoadLayer] = useState<any>(null);
  const [lastBounds, setLastBounds] = useState<[number, number, number, number]>([0, 0, 0, 0]);
  const [mapOptions, setMapOptions] = useState<GoogleMapReact.MapOptions>({});


  useEffect(() => {
    if (!roadLayer) return;
    if (selectedRoadTypes?.length && mapApi) {
      roadLayer.setMap(mapApi);
    } else {
      roadLayer.setMap(null);
    }
  }, [mapApi, selectedRoadTypes, roadLayer]);

  useEffect(() => {
    const getLocation = async () => {
      if (
        mapsApi?.Geocoder &&
        selectedVehicle?.position &&
        selectedVehicle?.vin !== selectedVehicleGeolocation?.vin
      ) {
        const geocoder = new mapsApi.Geocoder();

        const latLng = {
          lat: selectedVehicle.position.latitude,
          lng: selectedVehicle.position.longitude,
        };

        vehicleDispatch({
          type: SET_IS_LOADING_VEHICLE_GEOLOCATION,
          payload: true,
        });

        geocoder.geocode(
          {
            location: latLng,
          },
          (results: any[]) => {
            if (results && results.length > 0) {
              vehicleDispatch({
                type: SET_SELECTED_VEHICLE_GEOLOCATION,
                payload: {
                  vin: selectedVehicle.vin,
                  formattedStreet: getFormattedStreet(
                    results[0].address_components
                  ),
                  formattedArea: getFormattedArea(
                    results[0].address_components
                  ),
                  geolocationDateTime:
                    selectedVehicle.position?.positionDateTime,
                },
              });
            }
            vehicleDispatch({
              type: SET_IS_LOADING_VEHICLE_GEOLOCATION,
              payload: false,
            });
          }
        );
      }
    };
    getLocation();
  }, [
    selectedVehicle?.vin,
    selectedVehicle?.position,
    selectedVehicleGeolocation,
    mapsApi?.Geocoder,
    vehicleDispatch,
  ]);

  const flipLatLog = (gj: GeoJSON) => {
    if (gj.type === 'MultiLineString') {
      for (let i = 0; i < gj.coordinates.length; i++) {
        for (let j = 0; j < gj.coordinates[i].length; j++) {
          const tempPos = gj.coordinates[i][j][0];
          gj.coordinates[i][j][0] = gj.coordinates[i][j][1];
          gj.coordinates[i][j][1] = tempPos;
        }
      }
    } else if (gj.type === 'LineString') {
      for (let i = 0; i < gj.coordinates.length; i++) {
        const tempPos = gj.coordinates[i][0];
        gj.coordinates[i][0] = gj.coordinates[i][1];
        gj.coordinates[i][1] = tempPos;
      }
    } else {
      console.error("Could not convert " + gj.type);
    }
    return gj;
  }

  const updateLastBounds = (bounds: any) => {
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    setLastBounds([
      sw.lng(),
      sw.lat(),
      ne.lng(),
      ne.lat(),
    ]);
  }

  const boundsChanged = (bounds: any) => {
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    if (lastBounds[0] !== sw.lng()) return true;
    if (lastBounds[1] !== sw.lat()) return true;
    if (lastBounds[2] !== ne.lng()) return true;
    if (lastBounds[3] !== ne.lat()) return true;
    return false;
  }

  const setRoadData = useCallback(async (api: any, apiMap: any) => {
    if (!selectedRoadTypes || selectedRoadTypes.length <= 0) return;

    const rawBounds = apiMap.getBounds();

    const ne = rawBounds.getNorthEast();
    const sw = rawBounds.getSouthWest();

    updateLastBounds(rawBounds);

    const bounds = [
      sw.lng(),
      sw.lat(),
      ne.lng(),
      ne.lat(),
    ].join('%2C');


    let filters = ['egenskap=(10921%3D18269)', 'egenskap=(10897%3D21693)']
    for(const filter of filters) {
      let endpoint: string | null = [
        'https://nvdbapiles-v3.atlas.vegvesen.no/vegobjekter/900?segmentering=true',
        filter,
        'kartutsnitt=' + bounds,
        'inkluder=alle',
        'srid=4326',
      ].join('&');

      let fetched = 0;
      while (endpoint != null) {

        const res: Response = await fetch(endpoint);
        const obj = await res.json();
        fetched += obj.metadata.returnert;

        if (fetched >= obj.metadata.antall) {
          endpoint = null;
        } else {
          endpoint = obj.metadata.neste.href;
        }

        const objects = obj.objekter;

        const featureCol = {
          type: "FeatureCollection",
          features: objects.map((o: any) => {
            let str = o.geometri.wkt;
            // Remove Z axis data
            if (str.includes(' Z')) {
              str = str.replace(/ Z/, '');
              str = str.replace(/ -?\d+\.?\d*([,)])/g, '$1');
            }
            try {
              const geojson = wktToGeoJSON(str);
              const geometry = flipLatLog(geojson);
              const id = o.id;
              return ({ type: 'Feature', properties: [], id, geometry });
            } catch (e) {
              console.error(e);
              return [];
            }
          })
        };

        api.addGeoJson(featureCol);
      }
    }
  }, [selectedRoadTypes])

  useEffect(() => {
    if (roadLayer && mapApi) {
      setRoadData(roadLayer, mapApi);
    }
  }, [
    mapApi,
    roadLayer,
    setRoadData
  ]);

  useEffect(() => {
    if (mapsApi) {
      setMapOptions({
        mapTypeControl: true,
        mapTypeId: mapTypeId,
        mapTypeControlOptions: {
          style: mapsApi.MapTypeControlStyle.HORIZONTAL_BAR,
          position: mapsApi.ControlPosition.BOTTOM_CENTER,
          mapTypeIds: [mapsApi.MapTypeId.ROADMAP, mapsApi.MapTypeId.SATELLITE],
        },
        fullscreenControl: false,
      });
    }
  }, [mapTypeId, mapsApi]);

  const handleOnChange = (map: any) => {
    const newCenter = {
      lat: map.center.lat,
      lng: map.center.lng,
    };
    if (newCenter !== center) {
      mapDispatch({
        type: SET_CENTER,
        payload: newCenter,
      });
    }
    if (map.zoom !== zoom) {
      mapDispatch({
        type: SET_ZOOM,
        payload: map.zoom,
      });
    }
    if (map.bounds !== bounds) {
      mapDispatch({
        type: SET_BOUNDS,
        payload: map.bounds,
      });
    }

    if (roadLayer && mapApi && boundsChanged(mapApi.getBounds())) {
      setRoadData(roadLayer, mapApi);
    }
  };

  const handleOnMapTypeIdChange = (newMapTypeId: string) => {
    if (newMapTypeId !== mapTypeId) {
      mapDispatch({
        type: SET_MAP_TYPE_ID,
        payload: newMapTypeId,
      });
    }
  };

  const handleOnGoogleApiLoaded = (map: any, maps: any) => {
    setMapsApi(maps);
    setMapApi(map);
    const newMapLayer = new maps.Data({ map: map });
    setRoadLayer(newMapLayer);
    newMapLayer.setStyle({
      fillColor: 'green',
      strokeColor: 'blue',
    });
  };

  if (!appConfig) {
    return <span>Missing Google Maps API key</span>;
  }

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: appConfig.googleApiKey }}
      defaultCenter={defaultCenter}
      center={center}
      defaultZoom={defaultZoom}
      zoom={zoom}
      onChange={handleOnChange}
      onGoogleApiLoaded={({ map, maps }) => handleOnGoogleApiLoaded(map, maps)}
      onMapTypeIdChange={handleOnMapTypeIdChange}
      options={mapOptions}
      //layerTypes={["TrafficLayer"]}
      yesIWantToUseGoogleMapApiInternals
    >
      {children}
    </GoogleMapReact>
  );
};

export default GoogleMapView;
