import React from "react";
import { Fade, makeStyles, Theme } from "@material-ui/core";
import { Room } from "@material-ui/icons";
import { Card, CardContent } from "@material-ui/core";
import { parseISO } from "date-fns";
import CardHeader from "../../../../components/cardHeader";
import CardContentInfo from "../../../../components/cardContentInfo";
import IVehicleGeolocation from "../../../../api/types/vehicleGeolocation";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    margin: "1em",
    minHeight: "170px",
  },
  icon: {
    color: "#1b628e",
  },
}));

export const VehiclePositionCard = ({
  vehicleGeolocation,
  isLoading,
}: {
  vehicleGeolocation: IVehicleGeolocation | null;
  isLoading: boolean;
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.card} variant="outlined">
      <CardHeader
        icon={<Room />}
        title="Siste posisjon"
        dateTime={
          vehicleGeolocation?.geolocationDateTime
            ? parseISO(vehicleGeolocation?.geolocationDateTime)
            : null
        }
      ></CardHeader>
      <CardContent>
        <Fade in={!isLoading}>
          <CardContentInfo
            contentHead={vehicleGeolocation?.formattedStreet ?? "Ukjent"}
            contentValue={vehicleGeolocation?.formattedArea ?? ""}
          ></CardContentInfo>
        </Fade>
      </CardContent>
    </Card>
  );
};

export default VehiclePositionCard;
