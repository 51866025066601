import React from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { LocalShipping } from "@material-ui/icons";
import { Card, CardContent } from "@material-ui/core";
import CardHeader from "../../../../components/cardHeader";
import TitleValue from "../../../../components/titleValue";
import TitleValueRow from "../../../../components/titleValueRow";
import IVehicle from "../../../../api/types/vehicle";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    margin: "1em",
  },
}));

export const VehicleDataCard = ({ vehicle }: { vehicle: IVehicle }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card} variant="outlined">
      <CardHeader
        icon={<LocalShipping />}
        title={vehicle.groupName ? vehicle.groupName : "Kjøretøy"}
      />
      <CardContent>
        <TitleValueRow>
          <TitleValue title="Produsent" value={vehicle.brand} />
          <TitleValue title="Kategori" value={vehicle.mainCategoryName} />
          <TitleValue title="Modell" value={vehicle.model} />
        </TitleValueRow>
      </CardContent>
    </Card>
  );
};

export default VehicleDataCard;
