import React, { ChangeEvent } from "react";
import {
  makeStyles,
  Theme,
  Divider,
  InputBase,
  Card,
  IconButton,
} from "@material-ui/core";
import { Search, Clear } from "@material-ui/icons";
import { BACKGROUND_COLOR } from "../../../../layout/contants";
import { useMachineDispatch, useMachineState } from "../../../../context/machineContext";
import { SET_MACHINE_SEARCH_TEXT, SET_SELECTED_MACHINE } from "../../../../context/actions/machineActions";

const useStyles = makeStyles((theme: Theme) => ({
  searchWrapper: {
    flex: 0,
    padding: "1em",
    backgroundColor: BACKGROUND_COLOR,
  },
  searchInput: {
    padding: "0.5em",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 0,
  },
}));

export const MachineSearch = () => {
  const machineDispatch = useMachineDispatch();
  const { searchText = '', selectedMachine } = useMachineState();
  const classes = useStyles();

  const handleSearchOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    machineDispatch({ type: SET_MACHINE_SEARCH_TEXT, payload: event.target.value });
    machineDispatch({ type: SET_SELECTED_MACHINE, payload: null });
  };

  const handleSearchOnClear = () => {
    machineDispatch({ type: SET_MACHINE_SEARCH_TEXT, payload: "" });
    machineDispatch({ type: SET_SELECTED_MACHINE, payload: null });
  };

  return (
    <>
      <div className={classes.searchWrapper}>
        <Card
          component="form"
          className={classes.searchInput}
          variant="outlined"
        >
          {selectedMachine && (
            <IconButton
              aria-label="clear"
              onClick={handleSearchOnClear}
              className={classes.iconButton}
            >
              <Clear />
            </IconButton>
          )}

          <InputBase
            value={searchText}
            placeholder="Søk på maskin"
            inputProps={{ "aria-label": "Søk på maskin" }}
            onChange={handleSearchOnChange}
            className={classes.input}
          />
          <Search />
        </Card>
      </div>
      <Divider />
    </>
  );
};

