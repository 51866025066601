import React from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { HomeWork } from "@material-ui/icons";
import { Card, CardContent } from "@material-ui/core";
import CardHeader from "../../../../components/cardHeader";
import TitleValue from "../../../../components/titleValue";
import { IMachine } from "../../../../api/types/machine";
import { TitleValueCol } from "../../../../components/titleValueCol";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    margin: "1em",
  },
}));

export const MachineDataCard = ({ machine }: { machine: IMachine }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card} variant="outlined">
      <CardHeader
        icon={<HomeWork />}
        title={machine.modelName + (machine.assignedDriver ? ' - ' + machine.assignedDriver.fullName : '')}
      />
      <CardContent>
        <TitleValueCol>
          <TitleValue title="Internnummer" value={machine.internalNumber} />
          <TitleValue title="Underkategori" value={machine.subCategoryName} />
          <TitleValue title="Modellnavn" value={machine.modelName} />
        </TitleValueCol>
      </CardContent>
    </Card>
  );
};
