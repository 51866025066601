import { Divider, Input, makeStyles, MenuItem, Select, Theme } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import React, { ChangeEvent } from "react";
import { extractProp } from "../../../functions/array";

const useStyles = makeStyles((theme: Theme) => ({
  filterInput: {
    backgroundColor: "#fff",
    paddingLeft: "0.5em",
    paddingTop: "0.25em",
    paddingBottom: "0.25em",
    minWidth: 170,
    maxWidth: 170,
  },
  filterButton: {
    margin: theme.spacing(1),
    maxWidth: 170,
  },
}));

type Option = { name: string, value: string };

export const FilterDropdown = ({
  title,
  header,
  value,
  onChange,
  options
}: {
  title: string,
  header: string,
  value: Option[] | null,
  onChange: (event: ChangeEvent<{ name?: string | undefined; value: unknown; }>) => void,
  options: Option[],
}) => {
  const classes = useStyles();
  return (
    <Select
      title={title}
      multiple
      value={value !== null ? value.map((v) => v.value) : [] as Option[]}
      onChange={onChange}
      input={<Input className={classes.filterInput} />}
      displayEmpty
      renderValue={(v: any) => {
        const placeholder = <span style={{ color: 'grey' }}>{header}</span>;
        if (!v) return placeholder;
        if (!v.length) return placeholder;
        return extractProp<Option>((v as string[]).map(x =>
          options.find(o => o.value === x) || { name: x, value: x }
        ), 'name').join(', ');
      }}

    >
      <MenuItem disabled value="">
        <em>{header}</em>
      </MenuItem>
      <MenuItem value={"All"}>
        <Add /> <span>Velg alle</span>
      </MenuItem>
      <MenuItem value={"None"}>
        <Remove /> <span>Fjern alle</span>
      </MenuItem>
      <Divider />
      {options.map(o => (
        <MenuItem key={o.value + o.name} value={o.value}>
          {o.name}
        </MenuItem>
      ))}
    </Select>
  )
}
