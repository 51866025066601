import React, {
  createContext,
  useContext,
  useReducer,
  Dispatch,
  useEffect,
} from "react";
import { getMachines } from "../api/machineApi";
import { IMachine } from "../api/types/machine";
import Action from "../types/action";
import {
  SET_MACHINES,
  SET_MACHINE_SEARCH_TEXT,
  SET_SELECTED_MACHINE,
} from "./actions/machineActions";
import { useAuthState } from "./authContext";
import IMachineState from "./types/machineState";

const initialState: IMachineState = {
  machines: [],
  searchText: '',
  selectedMachine: null,
};

const MachineStateContext = createContext<IMachineState>(initialState);
const MachineDispatchContext = createContext<Dispatch<Action>>(() => { });

const machineReducer = (
  state: IMachineState,
  action: Action
): IMachineState => {
  switch (action.type) {
    case SET_MACHINES: {
      return {
        ...state,
        machines: action.payload,
      };
    }
    case SET_SELECTED_MACHINE: {
      return {
        ...state,
        selectedMachine: action.payload,
        searchText: (action.payload as IMachine)?.modelName || state.searchText,
      };
    }
    case SET_MACHINE_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.payload,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export const MachineProvider = ({ children }: { children: JSX.Element }) => {

  const [state, dispatch] = useReducer(machineReducer, {
    machines: [],
    searchText: '',
    selectedMachine: null,
  });
  const { account, getMachineAccessToken: getAccessToken } = useAuthState();

  // Fetch machine list
  useEffect(() => {
    if (account) {
      const fetchMachines = async () => {
        const payload = await getMachines(getAccessToken);
        if (payload) {
          dispatch({
            type: SET_MACHINES,
            payload,
          });
        }
      };

      fetchMachines();
    }
  }, [account, getAccessToken]);

  return (
    <MachineStateContext.Provider value={state}>
      <MachineDispatchContext.Provider value={dispatch}>
        {children}
      </MachineDispatchContext.Provider>
    </MachineStateContext.Provider>
  );
};


export const useMachineState = (): IMachineState => {
  const context = useContext(MachineStateContext);
  if (context === undefined) {
    throw new Error("useMachineState must be used within a machineProvider");
  }
  return context;
};

export const useMachineDispatch = (): Dispatch<Action> => {
  const context = useContext(MachineDispatchContext);
  if (context === undefined) {
    throw new Error("useMachineDispatch must be used within a machineProvider");
  }
  return context;
};
