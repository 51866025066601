import { SET_SELECTED_TAB } from '../context/actions/sidesheetActions';
import { useSidesheetDispatch } from '../context/sidesheetContext';
import { TabName } from '../context/types/sidesheetState';

export const useSwitchTab = () => {
    const dispatch = useSidesheetDispatch();

    return (tab: TabName) => {
        dispatch({ type: SET_SELECTED_TAB, payload: tab });
    }
}
