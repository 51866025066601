import React from "react";
import TitleBar from "./titleBar";
import Sidesheet from "./sidesheet";
import Loader from "../components/loader";
import clsx from "clsx";
import { useAuthState } from "../context/authContext";
import { useAppState } from "../context/appContext";

import { CssBaseline, Fade, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  main: {
    height: "100%",
  },
  loaderWrapper: {
    height: "100%",
  },
}));

export const Main = ({ children }: { children: JSX.Element }) => {
  const { isLoading } = useAppState();
  const { account } = useAuthState();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <TitleBar />
      {(account === null || isLoading) && (
        <Fade in={account === null || isLoading}>
          <div className={classes.loaderWrapper}>
            <Loader size={10} />
          </div>
        </Fade>
      )}
      {account !== null && !isLoading && (
        <Fade in={account !== null && !isLoading}>
          <main className={clsx(classes.main)}>
            <Sidesheet />
            {children}
          </main>
        </Fade>
      )}
    </div>
  );
};
