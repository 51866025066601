import { Box, makeStyles, Theme } from "@material-ui/core";
import React, { ReactNode } from "react";
import { TITLEBAR_HEIGHT } from "../layout/contants";

export interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value: any;
}

const useStyles = makeStyles((theme: Theme) => ({
    drawerContent: {
        flex: 1,
        height: `calc(100% - ${TITLEBAR_HEIGHT}px)`,
    },
}));

export function TabPanel<T>(props: { value: T, index: T, children?: ReactNode }) {
    const { children, value, index } = props;
    const classes = useStyles();

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            className={classes.drawerContent}
        >
            {value === index && (
                <Box height={'100%'}>
                    {children}
                </Box>
            )}
        </div>
    );
}
