import React from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { BACKGROUND_COLOR } from "../../../../layout/contants";
import { ProjectSearch } from "./ProjectSearch";
import { ProjectInfo } from "./ProjectInfo";
import { ProjectsTable } from "./ProjectsTable";
import { useProjectState } from "../../../../context/projectContext";

const useStyles = makeStyles((theme: Theme) => ({
  sidesheetWrapper: {
    backgroundColor: BACKGROUND_COLOR,
    height: "100%",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  sidesheetContent: {
    overflowY: "auto",
    flex: 1,
  },
}));

export const ProjectSidesheet = () => {
  const { selectedProject } = useProjectState();

  const classes = useStyles();

  return (
    <div className={classes.sidesheetWrapper}>
      <ProjectSearch />
      <div className={classes.sidesheetContent}>
        {selectedProject && <ProjectInfo />}
        {!selectedProject && <ProjectsTable />}
      </div>
    </div>
  );
};

