import React, {
  createContext,
  useContext,
  useEffect,
  useCallback,
  useState,
} from "react";
import {
  Configuration,
  PublicClientApplication,
  AuthenticationResult,
  AccountInfo,
  RedirectRequest,
} from "@azure/msal-browser";
import IAuthState from "./types/authState";

const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID ?? "",
    authority: process.env.REACT_APP_AUTHORITY,
    redirectUri: `${window.location.protocol}//${window.location.host}`,
  },
  cache: {
    cacheLocation: "localStorage",
  },
};

const loginRequest = {
  scopes: [
    "User.Read",
    "api://" + process.env.REACT_APP_MAP_API_CLIENT_ID + "/Vehicle.Read",
  ],
};

const emptyPromise = (): Promise<string | null> => Promise.resolve(null);

const initialState: IAuthState = {
  account: null,
  getAccessToken: emptyPromise,
  getProjectAccessToken: emptyPromise,
  getMachineAccessToken: emptyPromise,
  logout: () => { },
};

const AuthStateContext = createContext<IAuthState>(initialState);

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [authClient] = useState<PublicClientApplication>(
    new PublicClientApplication(msalConfig)
  );
  const [account, setAccount] = useState<AccountInfo | null>(null);


  /**
   * Get an access token either silently or with a redirect if that doesn't work
   * @param scope Single scope or list of scopes
   * @param redirect Unique url extension to return to
   * @returns token or null if not found
   */
  const getToken = useCallback(async (scope: string | string[], redirect: string = '') => {
    const scopes = typeof scope === 'string'
      ? [scope]
      : scope;

    if (account) {
      try {
        let token: AuthenticationResult | null = await authClient.acquireTokenSilent({
          account: account,
          scopes,
        });

        if (token?.accessToken) {
          return token.accessToken;
        }
      } catch (e) {
        authClient.acquireTokenRedirect({
          account: account,
          redirectUri: `${window.location.protocol}//${window.location.host
            }${redirect !== '' ? '/' : ''}${redirect}`,
          scopes,
        } as RedirectRequest);
      }
    }
    return null;
  }, [authClient, account])

  const getAccessToken = useCallback(async (): Promise<string | null> => {
    return getToken("api://" + process.env.REACT_APP_MAP_API_CLIENT_ID + "/Vehicle.Read");
  }, [getToken]);

  const getProjectAccessToken = useCallback(async (): Promise<string | null> => {
    return getToken("api://" + process.env.REACT_APP_PROSJEKT_CLIENT_ID + "/access");
  }, [getToken]);

  const getMachineAccessToken = useCallback(async (): Promise<string | null> => {
    return getToken("api://" + process.env.REACT_APP_MASKIN_CLIENT_ID + "/access");
  }, [getToken]);

  const logout = useCallback(() => {
    setAccount(null);
    authClient.logout();
  }, [authClient]);

  const getAccount = useCallback((): AccountInfo | null => {
    const currentAccounts = authClient.getAllAccounts();
    if (currentAccounts === null || currentAccounts.length === 0) {
      console.debug("No accounts detected");
      return null;
    }
    if (currentAccounts.length > 1) {
      console.debug(
        "Multiple accounts detected, need to add choose account code."
      );
      return currentAccounts[0];
    }
    if (currentAccounts.length === 1) {
      return currentAccounts[0];
    }
    return null;
  }, [authClient]);

  useEffect(() => {
    authClient
      .handleRedirectPromise()
      .then((response: AuthenticationResult | null) => {
        let account: AccountInfo | null = response?.account ?? null;

        if (!account) {
          account = getAccount();
        }

        if (!account) {
          authClient.loginRedirect(loginRequest);
        }

        setAccount(account);
      })
      .catch(console.error);
  }, [authClient, getAccount]);

  return (
    <AuthStateContext.Provider
      value={{
        account,
        getAccessToken,
        getProjectAccessToken,
        getMachineAccessToken,
        logout,
      }}
    >
      {children}
    </AuthStateContext.Provider>
  );
};

export const useAuthState = (): IAuthState => {
  const context = useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error("useAuthState must be used within a AuthProvider");
  }
  return context;
};
