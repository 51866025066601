import React, { ChangeEvent } from "react";
import {
  makeStyles,
  Theme,
  FormControl,
} from "@material-ui/core";

import {
  SET_SELECTED_MAIN_CATEGORIES,
  SET_SELECTED_SUB_CATEGORIES,
  SET_SELECTED_VEHICLE_GROUPS,
  SET_SELECTED_ROAD_TYPES,
} from "../context/actions/vehicleActions";

import { useVehicleDispatch, useVehicleState } from "../context/vehicleContext";
import { FilterDropdown } from "../pages/map/components/filterDropdown";

type ChangeEventType = ChangeEvent<{
  name?: string | undefined;
  value: unknown;
}>;

const useStyles = makeStyles((theme: Theme) => ({
  filterInput: {
    backgroundColor: "#fff",
    paddingLeft: "0.5em",
    paddingTop: "0.25em",
    paddingBottom: "0.25em",
  },
  filterButton: {
    margin: theme.spacing(1),
    minWidth: 100,
    maxWidth: 170,
  },
}));

export const MapFilters = () => {
  const {
    vehicleGroups,
    mainCategories,
    subCategories,
    roadTypes,
    selectedVehicleGroups,
    selectedMainCategories,
    selectedSubCategories,
    selectedRoadTypes,
  } = useVehicleState();
  const vehicleDispatch = useVehicleDispatch();

  const classes = useStyles();

  const updateValue = (type: string, selectedGroups: string[], all: { value: string }[]) => {
    if (selectedGroups.includes("All")) {
      vehicleDispatch({ type, payload: all, });
    } else if (selectedGroups.includes("None")) {
      vehicleDispatch({ type, payload: [], });
    } else {
      const newSelectedVehiclegroups = all.filter((vehicleGroup) =>
        selectedGroups.includes(vehicleGroup.value)
      );
      vehicleDispatch({ type, payload: newSelectedVehiclegroups, });
    }
  }

  const handleGroupOnChange = (event: ChangeEventType) => {
    const tmpSelectedGroups = event.target.value as string[];
    updateValue(
      SET_SELECTED_VEHICLE_GROUPS,
      tmpSelectedGroups,
      vehicleGroups,
    );
  };

  const handleMainCategoriesOnChange = (event: ChangeEventType) => {
    const tmpSelectedMainCategories = event.target.value as string[];
    updateValue(
      SET_SELECTED_MAIN_CATEGORIES,
      tmpSelectedMainCategories,
      mainCategories,
    );
  };

  const handleSubCategoriesOnChange = (event: ChangeEventType) => {
    const tmpSelectedSubCategories = event.target.value as string[];
    updateValue(
      SET_SELECTED_SUB_CATEGORIES,
      tmpSelectedSubCategories,
      subCategories,
    );
  };

  const handleRoadTypesOnChange = (event: ChangeEventType) => {
    const tmpSelectedRoadTypes = event.target.value as string[];
    updateValue(
      SET_SELECTED_ROAD_TYPES,
      tmpSelectedRoadTypes,
      roadTypes,
    );
  };

  return (
    <>
      <FormControl className={classes.filterButton}>
        <FilterDropdown
          title="Velg grupper"
          header="Grupper"
          value={selectedVehicleGroups}
          onChange={handleGroupOnChange}
          options={vehicleGroups.length > 0 ? vehicleGroups : selectedVehicleGroups}
        />
      </FormControl>
      <FormControl className={classes.filterButton}>
        <FilterDropdown
          title="Velg hovedkategorier"
          header="Hovedkategorier"
          value={selectedMainCategories}
          onChange={handleMainCategoriesOnChange}
          options={mainCategories}
        />
      </FormControl>
      <FormControl className={classes.filterButton}>
        <FilterDropdown
          title="Velg underkategorier"
          header="Underkategorier"
          value={selectedSubCategories}
          onChange={handleSubCategoriesOnChange}
          options={subCategories}
        />
      </FormControl>
      <FormControl className={classes.filterButton}>
        <FilterDropdown
          title="Velg vegklasse"
          header="Vegklasse"
          value={selectedRoadTypes}
          onChange={handleRoadTypesOnChange}
          options={roadTypes}
        />
      </FormControl>
    </>
  );
};

export default MapFilters;
