import axios from "axios";

import IVehicle from "./types/vehicle";
import IVehicleGroup from "./types/vehicleGroup";

const client = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  responseType: "json",
});

export const getVehicles = async (
  getAccessToken: () => Promise<string | null>
): Promise<IVehicle[] | null> => {
  try {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      throw new Error("Unable to get access token");
    }

    const response = await client.get<IVehicle[]>("/vehicle/vehicles", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("getVehicles", error);
    return null;
  }
};

export const getVehiclesGroups = async (
  getAccessToken: () => Promise<string | null>
): Promise<IVehicleGroup[] | null> => {
  try {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      throw new Error("Unable to get access token");
    }

    const response = await client.get<IVehicleGroup[]>("/vehicle/groups", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("getVehiclesgroups", error);
    return null;
  }
};
