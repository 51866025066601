import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AppProvider } from "./context/appContext";
import { AuthProvider } from "./context/authContext";
import { SidesheetProvider } from "./context/sidesheetContext";
import { Main } from "./layout/main";
import { LoginRedirect } from "./pages/auth/redirect";
import { Map } from "./pages/map";
import { VehicleProvider } from "./context/vehicleContext";
import { MapProvider } from "./context/mapContext";
import { ProjectProvider } from "./context/projectContext";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { MachineProvider } from "./context/machineContext";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1b628e',
    }
  }
});

function App() {
  return (
    <Router>
      <Switch>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <AppProvider>
              <MachineProvider>
                <VehicleProvider>
                  <ProjectProvider>
                      <MapProvider>
                        <SidesheetProvider>
                          <Main>
                            <>
                              <Route exact path="/login">
                                <LoginRedirect />
                              </Route>
                              <Route path="/">
                                <Map />
                              </Route>
                            </>
                          </Main>
                        </SidesheetProvider>
                      </MapProvider>
                  </ProjectProvider>
                </VehicleProvider>
              </MachineProvider>
            </AppProvider>
          </AuthProvider>
        </ThemeProvider>
      </Switch>
    </Router>
  );
}

export default App;
