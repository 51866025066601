import React from "react";

import { useMachineState } from "../../../../context/machineContext";
import { MachineDataCard } from "./machineDataCard";
import { MachineDriverCard } from "./MachineDriverCard";

export const MachineInfo = () => {
  const {
    selectedMachine,
  } = useMachineState();

  if (!selectedMachine) {
    return <span>Ingen kjøretøy valgt</span>;
  }

  const driver = selectedMachine?.assignedDriver || null;

  return (
    <>
      <MachineDataCard machine={selectedMachine} />
      {driver && (
        <MachineDriverCard
          title="Fører"
          name={driver.fullName}
          phone={driver.mobilePhoneWork || ''}
          email={driver.email}
        />
      )}
    </>
  );
};
