import React from "react";

export const VehicleMapPointer = ({
  children,
  lat,
  lng,
  text,
}: {
  children: JSX.Element;
  lat: number;
  lng: number;
  text: string;
}) => {
  return <div>{children}</div>;
};

export default VehicleMapPointer;
