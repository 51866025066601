import React, { useMemo } from "react";
import { Card, makeStyles, Theme } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import clsx from "clsx";
import { useMachineState } from "../../../../context/machineContext";
import { useTableSelect } from "../../../../hooks/useTableSelect";

const useStyles = makeStyles((theme: Theme) => ({
  vehicleRow: {
    cursor: "pointer",
  },
  regNoCell: {
    width: "8em",
  },
  missingValue: {
    color: "#757575",
  },
  catCell: {
    maxWidth: 100,
    paddingRight: 1,
    lineBreak: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cell: {
    lineBreak: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }
}));

export const MachinesTable = () => {
  const { machines, searchText } = useMachineState();
  const { selectMachine } = useTableSelect();

  const filteredMachines = useMemo(() => {
    const st = searchText.toLowerCase();
    return machines.filter((machine) => {
      return (
        machine.subCategoryName.toLowerCase().includes(st) ||
        machine.modelName.toLowerCase().includes(st) ||
        machine.assignedDriver?.fullName.toLowerCase().includes(st) ||
        machine.internalNumber.toString()?.toLowerCase().includes(st)
      );
    });
  }, [searchText, machines]);

  const classes = useStyles();

  return (
    <Card>
      <Table aria-label="Liste over prosjekter">
        <TableHead>
          <TableRow>
            <TableCell>Internnr.</TableCell>
            <TableCell>Modellnavn</TableCell>
            <TableCell>Fører</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredMachines.map((machine, index) => {
            return (
              <TableRow
                hover
                key={index}
                className={classes.vehicleRow}
                onClick={() => selectMachine(machine)}
              >
                <TableCell
                  className={clsx(classes.regNoCell, {
                  })}
                >
                  {machine.internalNumber}
                </TableCell>
                <TableCell className={classes.catCell}>
                  {machine.modelName}
                </TableCell>
                <TableCell className={classes.cell}>
                  {machine.assignedDriver?.fullName || ''}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Card>
  );
};

