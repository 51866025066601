import React from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { HomeWork } from "@material-ui/icons";
import { Card, CardContent } from "@material-ui/core";
import CardHeader from "../../../../components/cardHeader";
import TitleValue from "../../../../components/titleValue";
import IProject from "../../../../api/types/project";
import { TitleValueCol } from "../../../../components/titleValueCol";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    margin: "1em",
  },
}));

export const ProjectDataCard = ({ project }: { project: IProject }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card} variant="outlined">
      <CardHeader
        icon={<HomeWork />}
        title={project.id + ' - ' + project.projectName}
      />
      <CardContent>
        <TitleValueCol>
          <TitleValue title="Start dato" type="date" value={project.startDate} />
          <TitleValue title="Forventet sluttdato" type="date" value={project.expectedEndDate} />
          <TitleValue title="Byggherre" value={project.builder} />
          <TitleValue title="Oppdragsgiver" value={project.client} />
          <TitleValue title="Arbeidsomfang" value={project.scopeOfWork} />
        </TitleValueCol>
      </CardContent>
    </Card>
  );
};
