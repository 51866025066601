import React, { ChangeEvent } from "react";
import {
  makeStyles,
  Theme,
  Divider,
  InputBase,
  Card,
  IconButton,
} from "@material-ui/core";
import { Search, Clear } from "@material-ui/icons";
import { BACKGROUND_COLOR } from "../../../../layout/contants";
import { useProjectDispatch, useProjectState } from "../../../../context/projectContext";
import { SET_PROJECT_SEARCH_TEXT, SET_SELECTED_PROJECT } from "../../../../context/actions/projectActions";
import { preventDefault } from "../../../../functions/event";

const useStyles = makeStyles((theme: Theme) => ({
  searchWrapper: {
    flex: 0,
    padding: "1em",
    backgroundColor: BACKGROUND_COLOR,
  },
  searchInput: {
    padding: "0.5em",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 0,
  },
}));

export const ProjectSearch = () => {
  const projectDispatch = useProjectDispatch();
  const { searchText = '', selectedProject } = useProjectState();
  const classes = useStyles();

  const handleSearchOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    projectDispatch({ type: SET_PROJECT_SEARCH_TEXT, payload: event.target.value });
    projectDispatch({ type: SET_SELECTED_PROJECT, payload: null });
  };

  const handleSearchOnClear = () => {
    projectDispatch({ type: SET_PROJECT_SEARCH_TEXT, payload: "" });
    projectDispatch({ type: SET_SELECTED_PROJECT, payload: null });
  };

  return (
    <>
      <div className={classes.searchWrapper}>
        <Card
          component="form"
          className={classes.searchInput}
          variant="outlined"
          onSubmitCapture={preventDefault}
        >
          {selectedProject && (
            <IconButton
              aria-label="clear"
              onClick={handleSearchOnClear}
              className={classes.iconButton}
            >
              <Clear />
            </IconButton>
          )}

          <InputBase
            value={searchText}
            placeholder="Søk på prosjekt"
            inputProps={{ "aria-label": "Søk på prosjekt" }}
            onChange={handleSearchOnChange}
            className={classes.input}
          />
          <Search />
        </Card>
      </div>
      <Divider />
    </>
  );
};

