import React from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { Card, CardContent } from "@material-ui/core";
import { parseISO } from "date-fns";
import CardHeader from "../../../../components/cardHeader";
import TitleValue from "../../../../components/titleValue";
import TitleValueRow from "../../../../components/titleValueRow";
import IVehicle from "../../../../api/types/vehicle";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    margin: "1em",
  },
}));

function insertNumberSpace(number: Number) {
  return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
}

export const VehicleStatusCard = ({ vehicle }: { vehicle: IVehicle }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card} variant="outlined">
      <CardHeader
        icon={<Info />}
        title="Status"
        dateTime={
          vehicle.status?.statusDateTime
            ? parseISO(vehicle.status.statusDateTime)
            : null
        }
      />
      <CardContent>
        {vehicle?.status ? (
          <>
            <TitleValueRow>
              <TitleValue
                title="Hastighet"
                value={vehicle.status?.speed}
                suffix="km/t"
              />
              <TitleValue
                title="Kilometerstand"
                value={
                  vehicle.status?.mileage
                    ? insertNumberSpace(vehicle.status.mileage)
                    : ""
                }
                suffix="km"
              />
            </TitleValueRow>

            <TitleValueRow>
              <TitleValue
                title="Drivstoff"
                value={vehicle.status?.fuelLevel}
                suffix="%"
              />
              <TitleValue
                title="AdBlue"
                value={vehicle.status?.adBlueLevel}
                suffix="%"
              />
            </TitleValueRow>
          </>
        ) : (
          <Alert severity="info">Ingen data for status tilgjengelig...</Alert>
        )}
      </CardContent>
    </Card>
  );
};

export default VehicleStatusCard;
