import React from "react";
import { makeStyles, Theme, Tooltip } from "@material-ui/core";
import { Person } from "@material-ui/icons";
import { Card, CardContent } from "@material-ui/core";
import CardHeader from "../../../../components/cardHeader";
import IVehicle from "../../../../api/types/vehicle";
import TitleValueRow from "../../../../components/titleValueRow";
import TitleValue from "../../../../components/titleValue";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    margin: "1em",
  },
}));

export const VehicleDriverInfoCard = ({ vehicle }: { vehicle: IVehicle }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card} variant="outlined">
      <CardHeader icon={<Person />} title="Aktiv fører"></CardHeader>
      <CardContent>
        {vehicle?.activeDriver ? (
          <>
            <Tooltip title="Add" placement="top-start">
              <TitleValueRow>
                <TitleValue title="Navn" value={vehicle?.activeDriver?.name} />
              </TitleValueRow>
            </Tooltip>

            <TitleValueRow>
              <TitleValue
                title="Telefon"
                value={vehicle?.activeDriver?.phoneNumber}
              />
            </TitleValueRow>
            {vehicle?.activeDriver?.driverId && (
              <TitleValueRow>
                <TitleValue
                  title="Førerkortnummer"
                  value={vehicle?.activeDriver?.driverId}
                />
              </TitleValueRow>
            )}
          </>
        ) : (
          <Alert severity="info">
            Ingen data for aktiv fører tilgjengelig...
          </Alert>
        )}
      </CardContent>
    </Card>
  );
};

export default VehicleDriverInfoCard;
