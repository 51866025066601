import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  titleValueRow: {
    display: "flex",
    flexDirection: "row",
  },
});

export const TitleValueRow = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();

  return <div className={classes.titleValueRow}>{children}</div>;
};

export default TitleValueRow;
