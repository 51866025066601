import React from "react";

import { useVehicleState } from "../../../../context/vehicleContext";
import VehiclePositionCard from "./vehiclePositionCard";
import VehicleDriverInfoCard from "./vehicleDriverInfoCard";
import VehicleStatusCard from "./vehicleStatusCard";
import VehicleDataCard from "./vehicleDataCard";
import DrivingTimeCard from "../drivingTimeCard";

export const VehicleInfo = () => {
  const {
    selectedVehicle,
    selectedVehicleGeolocation,
    isLoadingVehicleGeolocation,
  } = useVehicleState();

  if (!selectedVehicle) {
    return <span>Ingen kjøretøy valgt</span>;
  }

  return (
    <>
      <VehicleDataCard vehicle={selectedVehicle} />
      <VehiclePositionCard
        vehicleGeolocation={selectedVehicleGeolocation}
        isLoading={isLoadingVehicleGeolocation}
      />
      <VehicleDriverInfoCard vehicle={selectedVehicle} />
      <VehicleStatusCard vehicle={selectedVehicle} />
      <DrivingTimeCard vehicle={selectedVehicle} />
    </>
  );
};

export default VehicleInfo;
