import IMainCategory from "../api/types/mainCategory";
import IVehicleGroup from "../api/types/vehicleGroup";
import ISubCategory from '../api/types/subCategory';

/**
 * Filters out duplicate objects from the array using the key
 * @param arr Array to filter
 * @param key Key to filter by
 * @returns Array filtered to be unique by key
 */
export const uniqueObj = <T extends Object>(arr: T[], key: keyof T) => {
    return arr.reverse().reduce((c, v) => c.some(cv => cv[key] === v[key]) ? c : [...c, v], [] as T[]);
}

/**
 * Extracts an array of keys from an array of objects
 * @param arr Array to extract from
 * @param key Key in object to extract
 * @returns Array of extracted keys
 */
export const extractProp = <T extends Object>(arr: T[], key: keyof T) => {
    return arr.reduce((c, v) =>  [...c, v[key]], [] as T[typeof key][]);
}

/**
 * Filters out duplicates from the array
 * @param arr Array to filter
 * @returns Filtered array
 */
export const uniqueSimple = <T extends string|number>(arr: T[]) => {
    return Array.from(new Set(arr));
}

/**
 * Find index of item in array - similar to some
 * @param arr Array to search
 * @param func Function to apply
 * @returns Index of where function returns true
 */
export const getIndex = <T extends Object>(arr: T[], func: (v: T) => boolean) => {
    for (let i = 0; i < arr.length; i+=1) {
        if (func(arr[i])) return i;
    }
    return null;
}

/**
 * Get the subcategories from a list of main categories
 * @param arr List of main categories
 */
export const getSubCategories = (arr: IMainCategory[]) =>
    arr.reduce((c, v) => {
        return [...c, ...v.subCategories]
    }, [] as ISubCategory[])


/**
 * Get the main categories from a list of vehicle groups
 * @param arr List of vehicle groups
 */
export const getMainCategories = (arr: IVehicleGroup[]) =>
    arr.reduce((c, v) => {
        return [...c, ...v.mainCategories]
    }, [] as IMainCategory[])


export const sortBySelected = <T extends object>(value: T, key: keyof T) => {
  return (a: T, b: T) => {
    return (a[key] === value[key] ? 1 : 0) - (b[key] === value[key] ? 1 : 0);
  }
}
