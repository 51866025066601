import React from "react";

import { makeStyles } from "@material-ui/core";
import {
  INACTIVE_COLOR,
  PRIMARY_PROJECT_COLOR,
  PRIMARY_PROJECT_HOVER_COLOR
} from "../../../../layout/contants";
import IProject from "../../../../api/types/project";

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: (props: any) => `${props.size}px`,
    width: (props: any) => `${props.size}px`,
    marginLeft: (props: any) => `-${props.size / 2}px`,
    marginTop: (props: any) => `-${props.size / 2}px`,
    "&:hover": {
      cursor: "pointer",
      "& $circle": {
        fill: PRIMARY_PROJECT_HOVER_COLOR,
      },
      "& $text": {
        backgroundColor: "#AAA",
        zIndex: 10,
      },
    },
  },
  text: {
    position: "absolute",
    marginTop: (props: any) => `${props.size + 25}px`,
    color: "#000",
    padding: "5px",
    paddingLeft: "10px",
    paddingRight: "10px",
    fontSize: "12px",
    fontWeight: "bold",
    borderRadius: "10px",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    whiteSpace: "nowrap",
  },
  selected: {
    border: "2px solid #1b628e",
    boxShadow: "0px 0px 6px 1px #1b628e88",
    backgroundColor: "#CCC",
    color: 'black',
  },
  vehicle: {
    color: "white",
  },
  circle: {
    fill: (props: any) => (props.isActive ? PRIMARY_PROJECT_COLOR : INACTIVE_COLOR),
    stroke: "#fff",
  },
});

export const ProjectMapPointer = ({
  size = 35,
  project,
  showId = false,
  selected = false,
  onClick = () => null,
}: {
  size?: number;
  project: IProject;
  showId?: boolean;
  selected?: boolean;
  onClick?: (machine: IProject) => void;
}) => {
  const classes = useStyles({
    size: size,
    isActive: true,
  });

  return (
    <div className={classes.wrapper} onClick={() => onClick(project)}>
      <svg width={50} height={50} viewBox="0 0 400 400">
        <defs>
          <filter id="drop-shadow" x="0" y="0" width="400" height="400">
            <feOffset result="offOut" in="SourceAlpha" dx="20" dy="20" />
            <feColorMatrix
              result="matrixOut"
              in="offOut"
              type="matrix"
              values=" 0.49 0 0 0 0 0 0.49 0 0 0 0 0 0.49 0 0 0 0 0 0.5 0"
            />
            <feGaussianBlur result="blurOut" in="matrixOut" stdDeviation="15" />
            <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
          </filter>
        </defs>
        <g>
          <circle
            strokeWidth="15"
            r={100}
            cy={200}
            cx={200}
            className={classes.circle}
            filter="url(#drop-shadow)"
          ></circle>
        </g>
      </svg>
      <span className={classes.text + (selected ? ' ' + classes.selected : '')}>
        {showId
          ? project.id
          : project.projectName
        }
      </span>
    </div>
  );
};

